import React from 'react'
import Searchbar from '../Utilitycomponents/Searchbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';



const Header = () => {

    const navigate  = useNavigate()

    function NavigatetoRoute(route)
    {
        navigate(route)
    }
  return (
    <>
    <div className='headContanier'>
    <div className='text-sm bg-black text-white py-2 flex justify-center'>
        Sign up and get 20% off to your first order. <u><a href='www.google.com'> Sign Up</a> Now</u>
    </div>
    <div className=' text-lg flex justify-evenly items-center py-5 border-2'>
      <div className="font-kashima text-5xl mt-2">
        Animestan
      </div>
      <button onClick={()=> NavigatetoRoute('/productlisting')}
      >Shop</button>
      <button>On Sale</button>
      <button>new Arival</button>
      <button>Brands</button>
      <div className='searchbox'>
       <Searchbar placeholder={'Search here....'}/>
      </div>
      <FontAwesomeIcon icon={faShoppingCart} />
      <FontAwesomeIcon icon={faUser} />
    </div>
    </div>
    </>
  )
}

export default Header