// src/CustomArrows.js
import React from 'react';

export const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} absolute top-1/2 right-4 transform -translate-y-1/2 z-10 cursor-pointer`}
      style={{ ...style, display: 'block', background: 'transparent' }}
      onClick={onClick}
    >
      <button className="text-3xl font-bold">&rarr;</button>
    </div>
  );
};

export const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} absolute top-1/2 left-4 transform -translate-y-1/2 z-10 cursor-pointer`}
      style={{ ...style, display: 'block', background: 'transparent' }}
      onClick={onClick}
    >
      <button className="text-3xl font-bold">&larr;</button>
    </div>
  );
};
