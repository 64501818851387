import React, { useState, useEffect } from "react";
import image1 from "../assets/anime-style-character-space.jpg";
import image2 from "../assets/swordman.jpg";
import image3 from "../assets/mythical-dragon-beast-anime-style.jpg";
import tshirt1 from "../assets/t-shirts/gojo_front_1.png";
import tshirt2 from "../assets/t-shirts/Luffy_tshirt_front_design.png";
import tshirt3 from "../assets/t-shirts/Shanks_Front_Design.png";
import tshirt4 from "../assets/t-shirts/Itachi_ucciha_printed_front_design.png";
import animeBanner1 from '../assets/Banner/one_piece_banner_pic.jpg'
import animeBanner2 from '../assets/Banner/Naruto_banner_pic.jpg'
import animeBanner3 from '../assets/Banner/jjk_banner_pic.jpg'
import animeBanner4 from '../assets/Banner/DS_banner_pic.jpg'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NextArrow, PrevArrow } from '../Utilitycomponents/SliderArrow';
import { useNavigate } from 'react-router-dom';


//json data

const products = [
  {
    id: 1,
    name: "Gojo Saturo Front Printed Oversized T-shirt",
    image: tshirt1,
    price: 449,
    rating: 4.5,
    reviews: 35,
  },
  {
    id: 2,
    name: "Monkey D Luffy Front Printed Oversized T-shirt",
    image: tshirt2,
    price: 519,
    originalPrice: 649,
    discount: 20,
    rating: 3.5,
    reviews: 325,
  },
  {
    id: 3,
    name: "Red Hair Shanks Front and Back Print Oversize T-shirt",
    image: tshirt3,
    price: 499,
    rating: 4.5,
    reviews: 455,
  },
  {
    id: 4,
    name: "Itachi Uchiha Front and Back Print Oversized T-shirt",
    image: tshirt4,
    price: 499,
    originalPrice: 640,
    discount: 30,
    rating: 4.5,
    reviews: 455,
  },
];

const categories = [
  { name: "One Piece", imageUrl: animeBanner1 },
  { name: "Naruto", imageUrl: animeBanner2},
  { name: "Jujutsu Kaisen", imageUrl: animeBanner3 },
  { name: "Demon Slayer", imageUrl: animeBanner4 },
];

const testimonials = [
    {
      id: 1,
      name: 'Sarah M.',
      review: "I'm blown away by the quality and style of the clothes I received from Shopco. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations.",
      rating: 5,
    },
    {
      id: 2,
      name: 'Alex K.',
      review: "Finding clothes that align with my personal style used to be a challenge until I discovered Shopco. The range of options they offer is truly remarkable, catering to a variety of tastes and occasions.",
      rating: 5,
    },
    {
      id: 3,
      name: 'James L.',
      review: "As someone who's always on the lookout for unique fashion pieces, I'm thrilled to have stumbled upon Shopco. The selection of clothes is not only diverse but also on-point with the latest trends.",
      rating: 5,
    },
    {
        id: 3,
        name: 'James L.',
        review: "As someone who's always on the lookout for unique fashion pieces, I'm thrilled to have stumbled upon Shopco. The selection of clothes is not only diverse but also on-point with the latest trends.",
        rating: 5,
      },
      {
        id: 3,
        name: 'James L.',
        review: "As someone who's always on the lookout for unique fashion pieces, I'm thrilled to have stumbled upon Shopco. The selection of clothes is not only diverse but also on-point with the latest trends.",
        rating: 5,
      },
    // Add more testimonials as needed
  ];

  

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="relative w-full h-screen">
      <button
        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-black bg-opacity-70 text-white px-4 py-2 z-10 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out"
        onClick={goToPreviousSlide}
      >
        &#10094;
      </button>
      <button
        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-black bg-opacity-70 text-white px-4 py-2 z-10 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out"
        onClick={goToNextSlide}
      >
        &#10095;
      </button>
      <div className="overflow-hidden h-full">
        <div
          className="flex transition-transform duration-500 ease-in-out h-full"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className="w-full flex-shrink-0 h-full"
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Banner = () => {
  return (
        <div className="overflow-hidden bg-black">
      <div className="flex justify-between animate-marquee space-x-8 text-xl text-white py-7">
        <div>One Piece</div>
        <div>Jujutsu Kaisen</div>
        <div>Naruto</div>
        <div>Hells Paradise</div>
        <div>Wind Breaker</div>
        <div>Black Clover</div>
        <div>Demon Salayer</div>
        <div>Dragon Ball Z</div>
        <div>Spy X family</div>
        <div>Tokoyo Goul</div>
      </div>
    </div>
  );
};

const CollectionPrevNewArrival = () => {

const navigate = useNavigate()

const handleButtonClick  =  () =>
    {
        navigate('/productlisting')
    }

  return (
    <>
    <div className="text-3xl flex justify-center content-center text-black my-10 font-bold">
      NEW ARRIVAL
    </div>
    <div>
      <div className="container mx-auto my-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {products.map((product) => (
            <div
              key={product.id}
              className="border rounded-lg p-4 bg-white shadow-md transform transition-transform duration-300 hover:scale-105"
            >
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-30 object-cover rounded-md transition-opacity duration-300 hover:opacity-75"
              />
              <h2 className="mt-4 text-lg font-semibold">{product.name}</h2>
              <div className="flex items-center mt-2">
                <div className="text-yellow-500">
                  {Array(Math.floor(product.rating)).fill("★").join("")}
                </div>
                <span className="ml-2 text-gray-500 text-sm">
                  {product.rating} ({product.reviews})
                </span>
              </div>
              <div className="mt-2">
                <span className="text-xl font-bold">₹{product.price}</span>
                {product.originalPrice && (
                  <span className="ml-2 text-gray-500 line-through">
                    ₹{product.originalPrice}
                  </span>
                )}
                {product.discount && (
                  <span className="ml-2 text-red-500">
                    -{product.discount}%
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <button className="px-8 py-2 bg-gray-800 text-white rounded-full hover:bg-gray-700"
          onClick={handleButtonClick}>
            View All
          </button>
        </div>
      </div>
    </div>
  </>
  );
};

const DressStyleBrowser = () => {
    return (
      <div className="w-full mx-auto p-8 bg-white shadow-lg rounded-lg my-2 px-10">
        <h1 className="text-3xl flex justify-center content-center text-black mb-10 font-bold">BROWSE BY ANIME</h1>
        <div className="grid grid-cols-2 gap-4">
          {categories.map((category) => (
            <div 
              key={category.name} 
              className="relative rounded-lg overflow-hidden h-64 transform transition-transform duration-300 hover:scale-95"
            >
              <img
                src={category.imageUrl}
                alt={category.name}
                className="w-full h-full object-cover transition-opacity duration-300 hover:opacity-75"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-300 hover:bg-opacity-75">
                <span className="text-white text-2xl font-bold">{category.name}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <button className="px-8 py-2 bg-gray-800 text-white rounded-full hover:bg-gray-700">
            View More
          </button>
        </div>
      </div>
    );
  };

  const Testimonials = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
     autoplaySpeed: 5000,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  
    return (
      <div className="container mx-auto py-12    overflow-hidden">
        <h1 className="text-3xl font-bold text-center mb-10">OUR HAPPY CUSTOMERS</h1>
        <Slider {...settings} className="relative">
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="px-4">
              <div className="border rounded-lg p-6 bg-white shadow-md h-full flex flex-col min-h-[250px] min-w-[250px] ">
                <div className="flex items-center mb-4">
                  <div className="text-yellow-500 text-xl">
                    {Array(testimonial.rating).fill('★').join('')}
                  </div>
                </div>
                <p className="text-gray-800 mb-4">{testimonial.review}</p>
                <h2 className="text-lg font-semibold mt-auto">{testimonial.name}</h2>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  };

  const Newsletter = () => {
    const [Email,setEmail] = useState('');

    const handleInputChanges = (event) =>
    {
        setEmail(event.target.value)
    }

    const handleSubmit = (event) =>
        {
            event.preventDefault();
            console.log("this is email==>>",Email)

            setEmail('');
        }
    return (
      <div className="bg-black text-white p-8 flex justify-center items-center mt-20 rounded-2px">
        <div className="max-w-7xl w-full flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
          <h2 className="text-3xl font-bold">
            STAY UP TO DATE ABOUT OUR LATEST OFFERS
          </h2>
          <form onSubmit={handleSubmit} className="flex space-x-2 w-full md:w-auto text-black" >
            <input
              type="email"
              value={Email}
              onChange={handleInputChanges}
              placeholder="Enter your email address"
              className="flex-grow p-3  rounded-l-md border-none focus:outline-none"
            />
            <button className="bg-white text-black px-4 py-3 rounded-r-md hover:bg-gray-200">
              Subscribe to Newsletter
            </button>
          </form>
        </div>
      </div>
    );
  };
  



const Home = () => {
  const images = [image1, image2, image3];

  return (
    <>
      <Carousel images={images} />
      <Banner />
      <CollectionPrevNewArrival />
      <DressStyleBrowser/>
      <Testimonials/>
      <Newsletter/>
    </>
  );
};

export default Home;
