// src/components/ProductListing.js
import React from 'react';
import Filter from './Filter';
import ProductCard from './ProductCard';
import image1 from  '../assets/t-shirts/Luffy_tshirt_front_design.png'
import image2 from  '../assets/t-shirts/gojo_front_1.png'
import image3 from  '../assets/t-shirts/Itachi_ucciha_printed_front_design.png'
import image4 from  '../assets/t-shirts/Shanks_Front_Design.png'
import { useNavigate } from 'react-router-dom';


const products = [
  {
    name: 'Monkey D Luffy Front Printed Oversized T-shirt',
    price: '519',
    rating: 4.5,
    review : 100,
    image: image1, // Replace with actual image path
  },
  {
    name: 'Gojo Saturo Front Printed Oversized T-shirt',
    price: '449',
    rating: 4.6,
    review : 100,
    image: image2, // Replace with actual image path
  },
  {
    name: 'Itachi Uchiha Front and Back Print Oversized T-shirt',
    price: '499',
    rating: 4.6,
    review : 100,
    image: image3, // Replace with actual image path
  },
  {
    name: 'Red Hair Shanks Front and Back Print Oversize T-shirt',
    price: '499',
    rating: 4.6,
    review : 100,
    image: image4, // Replace with actual image path
  },
  {
    name: 'Monkey D Luffy Front Printed Oversized T-shirt',
    price: '519',
    rating: 4.5,
    review : 100,
    image: image1, // Replace with actual image path
  },
  {
    name: 'Gojo Saturo Front Printed Oversized T-shirt',
    price: '449',
    rating: 4.6,
    review : 100,
    image: image2, // Replace with actual image path
  },
  {
    name: 'Itachi Uchiha Front and Back Print Oversized T-shirt',
    price: '499',
    rating: 4.6,
    review : 100,
    image: image3, // Replace with actual image path
  },
  {
    name: 'Red Hair Shanks Front and Back Print Oversize T-shirt',
    price: '499',
    rating: 4.6,
    review : 100,
    image: image4, // Replace with actual image path
  },
  {
    name: 'Monkey D Luffy Front Printed Oversized T-shirt',
    price: '519',
    rating: 4.5,
    review : 100,
    image: image1, // Replace with actual image path
  },
  {
    name: 'Gojo Saturo Front Printed Oversized T-shirt',
    price: '449',
    rating: 4.6,
    review : 100,
    image: image2, // Replace with actual image path
  },
  {
    name: 'Itachi Uchiha Front and Back Print Oversized T-shirt',
    price: '499',
    rating: 4.6,
    review : 100,
    image: image3, // Replace with actual image path
  },
  {
    name: 'Red Hair Shanks Front and Back Print Oversize T-shirt',
    price: '499',
    rating: 4.6,
    review : 100,
    image: image4, // Replace with actual image path
  },
  {
    name: 'Monkey D Luffy Front Printed Oversized T-shirt',
    price: '519',
    rating: 4.5,
    review : 100,
    image: image1, // Replace with actual image path
  },
  {
    name: 'Gojo Saturo Front Printed Oversized T-shirt',
    price: '449',
    rating: 4.6,
    review : 100,
    image: image2, // Replace with actual image path
  },
  {
    name: 'Itachi Uchiha Front and Back Print Oversized T-shirt',
    price: '499',
    rating: 4.6,
    review : 100,
    image: image3, // Replace with actual image path
  },
  {
    name: 'Red Hair Shanks Front and Back Print Oversize T-shirt',
    price: '499',
    rating: 4.6,
    review : 100,
    image: image4, // Replace with actual image path
  },
  // Add more products here
];

const ProductListing = () => {
    const navigate =  useNavigate()

    const handleGoBack =()=>
        {
          navigate(-1)
        }
  return (
    <div className="flex">
      <Filter />
      <div className="w-3/4 p-6">
      <div className="text-3xl flex justify-center content-center text-black my-10 font-bold">
      NEW ARRIVAL
    </div>
    <div className='flex justify-between content-center'>
    <button onClick={handleGoBack} className="px-4 py-2 border rounded my-2">Go Back</button>
    <div className='py-4'>Total Product Count : {products.length}</div>
    </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {products.map((product, index) => (
            <ProductCard key={index} product={product} />
          ))}
        </div>
        <div className="flex justify-between mt-4">
          <button className="px-4 py-2 border rounded">Previous</button>
          <div>
            <button className="px-4 py-2 border rounded">1</button>
            <button className="px-4 py-2 border rounded">2</button>
            <button className="px-4 py-2 border rounded">3</button>
            {/* Add more page buttons as needed */}
          </div>
          <button className="px-4 py-2 border rounded">Next</button>
        </div>
      </div>
    </div>
  );
};

export default ProductListing;
