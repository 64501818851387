// src/components/Filter.js
import React, { useState } from 'react';

const Filter = () => {
    const [price,setprice] = useState(0)

    const handlepricechange = (event) =>
        {
            setprice(event.target.value)
        }
  return (
    <div className="w-1/4 p-4 border-r">
      <div className="mb-4">
        <h3 className="font-bold">Filters</h3>
        {/* Add filter sections here */}
      </div>
      {/* Price Filter */}
      <div className="mb-4">
        <h4 className="font-semibold">Price</h4>
        <input type="range" min="0" max="1000" className="w-full" onChange={handlepricechange} value={price} />
        <div className="text-right">₹{price}</div> {/* Display current price */}
      </div>
      {/* Colors Filter */}
      <div className="mb-4">
        <h4 className="font-semibold">Colors</h4>
        <div className="flex space-x-2">
          {/* Add color buttons here */}
          <button className="w-6 h-6 bg-red-500 rounded-full"></button>
          <button className="w-6 h-6 bg-blue-500 rounded-full"></button>
          <button className="w-6 h-6 bg-gray-500 rounded-full"></button>
          <button className="w-6 h-6 bg-black rounded-full"></button>
          <button className="w-6 h-6 bg-green-500 rounded-full"></button>
          <button className="w-6 h-6 bg-purple-500 rounded-full"></button>
          <button className="w-6 h-6 bg-orange-500 rounded-full"></button>
          <button className="w-6 h-6 bg-yellow-500 rounded-full"></button>
          <button className="w-6 h-6 bg-beige-500 rounded-full border-2"></button>
          {/* Add more colors as needed */}
        </div>
      </div>
      {/* Size Filter */}
      <div className="mb-4">
        <h4 className="font-semibold">Size</h4>
        <div className="flex flex-wrap space-x-2">
          <button className="px-2 py-1 border rounded">S</button>
          <button className="px-2 py-1 border rounded">M</button>
          <button className="px-2 py-1 border rounded">L</button>
          <button className="px-2 py-1 border rounded">XL</button>
        </div>
      </div>
      {/* Dress Style Filter */}
      <div className="mb-4">
        <h4 className="font-semibold">Dress Style</h4>
        <div className="flex flex-wrap space-x-2">
          <button className="px-2 py-1 border rounded">Oversized</button>
          <button className="px-2 py-1 border rounded">Regular Fits</button>
          <button className="px-2 py-1 border rounded">Polo T-shirts</button>
        </div>
      </div>
      <button className="bg-black text-white px-4 py-2 rounded">Apply Filter</button>
    </div>
  );
};

export default Filter;
