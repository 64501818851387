import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faInstagram, faPinterestP } from '@fortawesome/free-brands-svg-icons';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { faCcPaypal } from '@fortawesome/free-brands-svg-icons';
import { faCcApplePay } from '@fortawesome/free-brands-svg-icons';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { faCcAmazonPay } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-10">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="mb-8 md:mb-0">
            <h2 className="font-bold text-3xl mb-4 font-kashima">Animestan</h2>
            <p className="mb-4">
              We have clothes that suit your style and which you're proud to wear. For anime weebs.
            </p>
            <div className="flex space-x-4">
              <a href="#" aria-label="Twitter">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="#" aria-label="Facebook">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="#" aria-label="Instagram">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="#" aria-label="Pinterest">
                <FontAwesomeIcon icon={faPinterestP} />
              </a>
            </div>
          </div>
          <div className="mb-8 md:mb-0">
            <h3 className="font-bold mb-4">COMPANY</h3>
            <ul>
              <li className="mb-2"><a href="#">About</a></li>
              <li className="mb-2"><a href="#">Features</a></li>
              <li className="mb-2"><a href="#">Works</a></li>
              <li className="mb-2"><a href="#">Career</a></li>
            </ul>
          </div>
          <div className="mb-8 md:mb-0">
            <h3 className="font-bold mb-4">HELP</h3>
            <ul>
              <li className="mb-2"><a href="#">Customer Support</a></li>
              <li className="mb-2"><a href="#">Delivery Details</a></li>
              <li className="mb-2"><a href="#">Terms & Conditions</a></li>
              <li className="mb-2"><a href="#">Privacy Policy</a></li>
            </ul>
          </div>
          <div className="mb-8 md:mb-0">
            <h3 className="font-bold mb-4">FAQ</h3>
            <ul>
              <li className="mb-2"><a href="#">Account</a></li>
              <li className="mb-2"><a href="#">Manage Deliveries</a></li>
              <li className="mb-2"><a href="#">Orders</a></li>
              <li className="mb-2"><a href="#">Payments</a></li>
            </ul>
          </div>
          <div className="mb-8 md:mb-0">
            <h3 className="font-bold mb-4">RESOURCES</h3>
            <ul>
              <li className="mb-2"><a href="#">Free eBooks</a></li>
              <li className="mb-2"><a href="#">Development Tutorial</a></li>
              <li className="mb-2"><a href="#">How to - Blog</a></li>
              <li className="mb-2"><a href="#">Youtube Playlist</a></li>
            </ul>
          </div>
        </div>
        <div className="mt-8 flex flex-col md:flex-row justify-between items-center border-t pt-4">
          <p className="text-sm text-gray-600">Animestan © 2023-2023, All Rights Reserved</p>
          <div className="flex space-x-4 mt-4 md:mt-0">
            <FontAwesomeIcon icon={faCcVisa} size="2x" />
`            <FontAwesomeIcon icon={faCcMastercard} size="2x" />
            <FontAwesomeIcon icon={faCcPaypal} size="2x" />
            <FontAwesomeIcon icon={faCcApplePay} size="2x" />
            <FontAwesomeIcon icon={faCcAmazonPay} size="2x" />`
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
