import "./App.css";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import ProductListing from "./Components/MainProductListing";
import Header from "./Components/header";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return <>
  <Router>
    <Header/>
    <Routes>
      <Route path = {'/'} element ={<Home/>}/>
      <Route path = {'/productlisting'} element ={<ProductListing/>}/>
    </Routes>
    <Footer/>
  </Router>
  </>
}

export default App;
