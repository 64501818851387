// src/components/ProductCard.js
import React from 'react';

const ProductCard = ({ product }) => {
  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <span key={index} className={index < rating ? 'text-yellow-500' : 'text-gray-300'}>
        ★
      </span>
    ));
  };

  return (
    <div className="p-4 border rounded">
      <img src={product.image} alt={product.name} className="w-full h-64 object-cover mb-2" />
      <h3 className="font-semibold">{product.name}</h3>
      <div className="flex items-center mb-2">
        {renderStars(product.rating)}
        <span className="ml-2 text-gray-600">{product.rating.toFixed(1)} ({product.review})</span>
      </div>
      <p className="text-gray-500">₹{product.price}</p>
    </div>
  );
};

export default ProductCard;
